export function scrollDirection() {
    return {
        isScrollingUp: true,
        isScrollingDown: false,
        lastScrollTop: 0,
        scrollThreshold: 200, // Zone morte de 200px

        handleScroll() {
            const scrollToTop = window.pageYOffset || document.documentElement.scrollTop;

            // Si aucune différence de scroll, ne pas changer l'état
            if (scrollToTop === this.lastScrollTop) {
                this.isScrollingUp = false;
                this.isScrollingDown = false;
                return;
            }

            // Calcul de la distance parcourue
            const scrollDelta = scrollToTop - this.lastScrollTop;

            // Vérification de dépassement du seuil
            if (Math.abs(scrollDelta) > this.scrollThreshold) {
                if (scrollDelta > 0) {
                    // Scroll vers le bas
                    this.isScrollingUp = false;
                    this.isScrollingDown = true;
                } else {
                    // Scroll vers le haut
                    this.isScrollingUp = true;
                    this.isScrollingDown = false;
                }

                // Mettre à jour la dernière position seulement si le seuil est dépassé
                this.lastScrollTop = scrollToTop;
            }
        },
    };
}
