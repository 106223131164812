import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
    Alpine.store('favoritesProperties', {
        fetched: false,
        properties: [],
    })
})

export default () => ({
    get properties() {
        return this.$store.favoritesProperties.properties;
    },
    set properties(value) {
        this.$store.favoritesProperties.properties = value;
    },

    init() {
        if(!this.$store.favoritesProperties.fetched) {
            this.fetchFavoritesProperties();
        }
    },

    toggleFavorite(property_id) {
        if (this.properties.includes(property_id)) {
            this.properties = this.properties.filter(id => id !== property_id);
        } else {
            this.properties.push(property_id);
        }
        // sessionStorage.setItem('favorites', JSON.stringify(this.properties));
        this.syncProperties();
    },

    isFavorite(property_id) {
        return this.properties.includes(property_id);
    },

    syncProperties() {
        axios.post('/favorites', {properties: this.properties})
            .then(response => {
                this.properties = response.data;
            });
    },

    fetchFavoritesProperties() {
        axios.get('/favorites').then(response => {
            this.properties = response.data;
            this.$store.favoritesProperties.fetched = true;
        });
    },
});
