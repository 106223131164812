import Alpine from 'alpinejs'


Alpine.store('property', {
    fetched: false,
    rooms: 1,
    author_name: '',
    disabled_dates: [],

    init() {
    },

    async fetch(slug) {
        let response = await axios.get('/api/properties/'+slug )
        this.rooms = response.data.rooms;
        this.long_name = response.data.long_name;
        this.short_name = response.data.short_name;
        this.author_name = response.data.author_name;
        this.author_email = response.data.author_email;
        this.property_id = response.data.id;
        this.disabled_dates = response.data.disabled_dates;
        this.fetched = true;

        window.dispatchEvent(new CustomEvent('propertyFetched', {bubbles: true}));
    }


})
