import Alpine from 'alpinejs';
export default (slug, currency) => ({
    form: {
        period: {
            from: '',
            to: '',
        },
        days: 0,
        rooms: 0,
        bedrooms_label: 'Select Bedrooms',
        currency: 'USD',
        first_name: '',
        last_name: '',
        email: '',
        prefix: 'US_1',
        phone: '',
        message: '',
    },
    bookForm: null,
    bookFormFields: {
        period: {
            from: 'input_12',
            to: 'input_13',
        },
        days: 'input_20',
        villa: 'input_9',
        villa_short_name: 'input_17',
        villa_long_name: 'input_18',
        author_name: 'input_10',
        author_email: 'input_15',
        bedrooms: 'input_14'
    },
    price: 0,
    formattedPrice: '',
    nights: 0,
    totalRooms : 1,


    async init() {
        this.bookForm = this.$el.querySelector('.form-book');

        this.form.currency = currency;
        if(!this.$store.property.fetched) {
            await this.$store.property.fetch(slug);
        }
        this.totalRooms = this.$store.property.rooms;

        if (this.$store.filters.filters.date.start) {
            this.form.period.from = this.$store.filters.filters.date.start;
        }

        if (this.$store.filters.filters.date.end) {
            this.form.period.to = this.$store.filters.filters.date.end;
        }

        if (this.$store.filters.filters.date.start && this.$store.filters.filters.date.end) {
            this.form.days = Math.ceil((new Date(this.form.period.to) - new Date(this.form.period.from)) / (1000 * 60 * 60 * 24));
        }

        if (this.$store.property.long_name) {
            this.form.long_name = this.$store.property.long_name;
        }

        if (this.$store.property.short_name) {
            this.form.short_name = this.$store.property.short_name;
        }

        await this.calcul();

        window.addEventListener('clearDate', () => this.form.rooms = 0);

        window.addEventListener('dateChange', () => {
            this.calcul();
        });

        Alpine.effect(() => {
            const rooms = this.form.rooms;
            this.bedroomLabel();
        });
    },

    async calcul()  {
        this.setDate();

        if(this.form.period.from !== '' && this.form.period.to !== '') {
            this.form.rooms = this.form.rooms > 0 ? this.form.rooms : 1;
        }

        let calcul = await axios.post('/api/properties/'+slug+'/price', this.form);
        this.nights = calcul.data.nights;
        this.formattedPrice = calcul.data.formattedPrice;
        this.price = calcul.data.price;
        this.sendEventInputs();
        this.bedroomLabel();
    },

    async bedroomLabel()  {
        this.form.bedrooms_label = 'Select Bedrooms';
        if( this.form.rooms == 1 ){
            this.form.bedrooms_label = ' 1 Bedroom';
        }
        if( this.form.rooms > 1 ){
            this.form.bedrooms_label = this.form.rooms + ' Bedrooms';
        }
        //console.log(this.form.bedrooms_label);
    },

    sendEventInputs() {
        //window.dispatchEvent(new CustomEvent('setInputs', {
        this.$el.dispatchEvent(new CustomEvent('setInputs', {
            bubbles: true,
            detail: [
                {
                    name: this.bookFormFields.author_name,
                    value: this.$store.property.author_name,
                },
                {
                    name: this.bookFormFields.author_email,
                    value: this.$store.property.author_email,
                },
                {
                    name: this.bookFormFields.villa,
                    value: this.$store.property.property_id,
                },
                {
                    name: this.bookFormFields.villa_short_name,
                    value: this.$store.property.short_name,
                },
                {
                    name: this.bookFormFields.villa_long_name,
                    value: this.$store.property.long_name,
                },
                {
                    name: this.bookFormFields.period.from,
                    value: this.form.period.from,
                },
                {
                    name: this.bookFormFields.period.to,
                    value: this.form.period.to,
                },
                {
                    name: this.bookFormFields.days,
                    value: this.form.days,
                },
                {
                    name: this.bookFormFields.bedrooms,
                    value: this.form.rooms,
                }
            ]
        }));
    },

    setDate() {
        this.form.period.from = this.$store.filters.filters.date.start ?? '';
        this.form.period.to = this.$store.filters.filters.date.end ?? '';
    },


});
