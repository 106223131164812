
window.addEventListener('DOMContentLoaded', function() {
    // Form Contact
    document.querySelectorAll('.form-contact').forEach(form => {
        const event = new CustomEvent('populateForm', {
            detail: {
                formSelector: '.form-contact',
                fieldName: 'input_9',
                value: 'US_1',
                targetElement: 'select[name="input_9"]'
            }
        });
        window.dispatchEvent(event);
    });

    // Form Book
    document.querySelectorAll('.form-book-a-villa').forEach(form => {
        const event = new CustomEvent('populateForm', {
            detail: {
                formSelector: '.form-book-a-villa',
                fieldName: 'input_21',
                value: 'US_1',
                targetElement: 'select[name="input_21"]'
            }
        });
        window.dispatchEvent(event);
    });
});
