
// Bootstrap 5.2 components

import * as Popper from "@popperjs/core"
import { Collapse } from 'bootstrap';
import { Carousel } from 'bootstrap';
import { Modal } from 'bootstrap';
import { Offcanvas } from 'bootstrap';
import { Tab } from 'bootstrap';
import { Popover } from 'bootstrap';

/*
export { default as Alert } from './src/alert.js'
export { default as Button } from './src/button.js'
export { default as Carousel } from './src/carousel.js'
export { default as Collapse } from './src/collapse.js'
export { default as Dropdown } from './src/dropdown.js'
export { default as Modal } from './src/modal.js'
export { default as Offcanvas } from './src/offcanvas.js'
export { default as Popover } from './src/popover.js'
export { default as ScrollSpy } from './src/scrollspy.js'
export { default as Tab } from './src/tab.js'
export { default as Toast } from './src/toast.js'
export { default as Tooltip } from './src/tooltip.js'
*/

// Enable Popover
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))

// Carousel
let imgCarousels = Array.prototype.slice.call(
    document.querySelectorAll('.carousel.with-lazyload')
);

setTimeout(function() {

    // Carousel Lazyload
    imgCarousels.forEach(function (imgCarousel) {

        imgCarousel.addEventListener('slide.bs.carousel', function (event) {

            // img src
            var image = event.relatedTarget.querySelector('img[data-bs-src]');
            if( image && image.dataset.bsSrc !== undefined ){
                image.src = image.dataset.bsSrc;
                image.removeAttribute('bs-src');
            }

            // srcset
            var sources = event.relatedTarget.querySelectorAll('source[data-bs-srcset]');
            sources.forEach(function (source) {
                if( source && source.dataset.bsSrcset !== undefined ){
                    source.srcset = source.dataset.bsSrcset;
                    source.removeAttribute('bs-srcset');
                }
            })

        });

    });

}, 200);
