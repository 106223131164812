import Alpine from 'alpinejs';
export default (slug) => ({
    year: null,
    currency: 'USD',
    period: 'week',



    init() {
        this.year = new Date().getFullYear();
    },

    setYear(year) {
        this.year = year;
    },

    setCurrency(currency) {
        this.currency = currency;
    },

    setPeriod(period) {
        this.period = period;
    },

    inCurrentYearPeriod(start, end) {
        let nextYear = this.year + 1;
        let startFrom = this.toDate(start);
        let endFrom =this.toDate(end);

        return startFrom.getFullYear() === this.year && (
            endFrom.getFullYear() === this.year ||
            endFrom.getFullYear() === nextYear
        )
    },

    toDate(dateString) {
        var parts = dateString.split('/');
        var day = parseInt(parts[0]);
        // remove leading zeros from month numbers
        var month = parseInt(parts[1]) - 1; // Soustraire 1 car les mois sont indexés à partir de 0
        var year = parseInt(parts[2]);
        return new Date(year, month, day);
    }



});
