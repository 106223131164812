// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// Import Swiper bundle optimized
//import Swiper from 'swiper';
//import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
//Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

window.swiper = Swiper;


let sliders = Array.prototype.slice.call(
    document.querySelectorAll('.swiper-container')
);

const swiperGalleriesConfig = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 400,
    hashNavigation: false,
    watchOverflow: true,
    observer: true,
    observeParents: true,
    allowTouchMove: false,
    navigation: {
        nextEl: '#sliderPropertyGalleriesNavNext',
        prevEl: '#sliderPropertyGalleriesNavPrev',
    },
    breakpoints: {
        768: {
            slidesPerView: 'auto',
            spaceBetween: 24,
        },
        1024: {
            slidesPerView: 'auto',
            spaceBetween: 24,
        },
    },
};

setTimeout(function() {

    // Init Tablet, Laptop, Desktop
    sliders.forEach(function (slider) {
        if (window.innerWidth > 576) {
            window.sliderPropertyGalleries = new Swiper('.swiper-container', swiperGalleriesConfig);
        }
    });

    // Events
    const jumpGalleryExteriors = document.querySelector('.jumpGalleryExteriors');
    const jumpGalleryInteriors = document.querySelector('.jumpGalleryInteriors');
    const jumpGalleryBedrooms = document.querySelector('.jumpGalleryBedrooms');

    if(jumpGalleryExteriors !== null) {
        jumpGalleryExteriors.onclick = function (e) {
            e.preventDefault();
            sliderPropertyGalleries.slideTo(0, 400);
        };
    }

    if(jumpGalleryInteriors !== null) {
        jumpGalleryInteriors.onclick = function (e) {
            e.preventDefault();
            var jumper = document.querySelector('.swiper-slide[data-hash="interior"]');
            var jumper_index = jumper.dataset.index;
            if( jumper_index > 0 ){
                sliderPropertyGalleries.slideTo(jumper_index, 400);
            }
        };
    }

    if(jumpGalleryBedrooms !== null) {
        jumpGalleryBedrooms.onclick = function (e) {
            e.preventDefault();
            var jumper = document.querySelector('.swiper-slide[data-hash="bedroom"]');
            var jumper_index = jumper.dataset.index;
            if( jumper_index > 0 ){
                sliderPropertyGalleries.slideTo(jumper_index, 400);
            }
        };
    }

    // Enable active on links
    var toogleGalleryLink = function (info, eventName) {

        var indexCurrent = sliderPropertyGalleries.activeIndex;
        var linksGallery = document.querySelector('.jumpGalleries');
        var currentGallery = document.querySelector('.item_num_'+indexCurrent);

        // remove
        linksGallery.classList.remove('active-exterior');
        linksGallery.classList.remove('active-interior');
        linksGallery.classList.remove('active-bedroom');

        // add
        if (currentGallery.classList.contains('item-exterior')) {
            linksGallery.classList.add('active-exterior');
        }
        if (currentGallery.classList.contains('item-interior')) {
            linksGallery.classList.add('active-interior');
        }
        if (currentGallery.classList.contains('item-bedroom')) {
            linksGallery.classList.add('active-bedroom');
        }

    }

    if (typeof sliderPropertyGalleries !== 'undefined' && sliderPropertyGalleries !== null) {
        sliderPropertyGalleries.on('activeIndexChange', toogleGalleryLink);
    }

}, 10);

// Mobile : disable swiper
function destroySwiper() {
    if (typeof sliderPropertyGalleries !== 'undefined' && sliderPropertyGalleries !== null) {
        sliderPropertyGalleries.destroy(true, true);
        sliderPropertyGalleries = null;
    }
}

function handleGallerieResize() {
    if (window.innerWidth < 576) {
        destroySwiper();
    } else {
        if (typeof sliderPropertyGalleries === 'undefined' || sliderPropertyGalleries === null) {
            window.sliderPropertyGalleries = new Swiper('.swiper-container', swiperGalleriesConfig);
        }
    }
}

window.addEventListener('resize', handleGallerieResize);
