window.simpleForm = function() {
    return {
        inputs: {},
        loading: false,
        errors: {},
        message: null,
        init() {
            this.$el.querySelectorAll('input[type=hidden]').forEach(input => {
                this.inputs[input.name] = input.dataset.value.indexOf(',') > -1 ? input.dataset.value.split(',') : input.dataset.value;
            });

            /*
            // Disable -> apply a mapping fields of book form in all forms!
            window.addEventListener('setInputs', (e) => {
                for (let key in e.detail) {
                    this.inputs[e.detail[key].name] = e.detail[key].value;
                }
            });*/
            // Mapping fields only for .form-book
            window.addEventListener('setInputs', (e) => {

                if (!this.$el.matches('.form-book-a-villa')) return;

                // White list
                /*const allowedFields = [
                    'input_12', 'input_13', 'input_20',
                    'input_17', 'input_18', 'input_10',
                    'input_15', 'input_14'
                ];*/

                for (let key in e.detail) {
                    const field = e.detail[key];
                    //if (allowedFields.includes(field.name)) {
                        this.inputs[field.name] = field.value;
                    //}
                }
            });

            // Add default value for specific field
            window.addEventListener('populateForm', (e) => {
                if (this.$el.matches(e.detail.formSelector)) {
                    // Update Alpine field
                    this.inputs[e.detail.fieldName] = e.detail.value;

                    // Dom dispatch
                    const targetElement = this.$el.querySelector(e.detail.targetElement);
                    if (targetElement) {
                        targetElement.value = e.detail.value;

                        // Déclencher tous les événements nécessaires
                        targetElement.dispatchEvent(new Event('input'));
                        targetElement.dispatchEvent(new Event('change'));
                        targetElement.dispatchEvent(new Event('blur'));
                    }

                    // Update hidden gravity fields
                    const hiddenInput = this.$el.querySelector(`input[type="hidden"][name="${e.detail.fieldName}"]`);
                    if (hiddenInput) {
                        hiddenInput.value = e.detail.value;
                        hiddenInput.dispatchEvent(new Event('change'));
                    }

                    //console.log('Add default value for :', e.detail.fieldName, this.inputs[e.detail.fieldName]);
                }
            });
        },
        async submit() {
            this.errors = {};
            let action = this.$refs.form.action;
            this.loading = true;
            let response = null;

            try {
                response = await axios.post(action, {
                    fields: this.inputs,
                });

                if (response.data.error) {
                    const newErrors = {};
                    response.data.errors.forEach(error => {
                        newErrors['input_' + error.field_id] = error.message;
                    });

                    // react -> reassign error
                    this.errors = { ...newErrors };
                    this.$nextTick(() => {
                        this.loading = false;
                    });
                    return;
                }

                this.message = response.data.message;
                this.inputs = {};
            } catch (error) {
                console.log(error.response.data);
            }

            this.loading = false;
        }
    }
}
