import Alpine from 'alpinejs'
import moment from 'moment'

Alpine.store('filters', {
    filters: {
        bedrooms: [],
        collections: [],
        locations: [],
        amenities: [],
        price: {
            min: null,
            max: null,
        },
        date: {
            start: null,
            end: null,
            flexibleInDays: 0,
        },
    },
    excludeFilters: ['page'],
    properties_count: 0,
    inputDate: null,
    priceText: {
        min: null,
        max: null,
    },
    currency: 'USD',

    init() {
        // this.clear();
        if(this.hasFiltersInUrl()) {
            this.loadFiltersFromUrl();
        } else {
            this.loadFiltersFromSession();
        }

        Alpine.effect(() => {
            const flexibleInDays = this.filters.date.flexibleInDays;
            this.dispatchReloadCalendar();
        });

        Alpine.effect(() => {
            const filters = this.filters;
            this.storeFiltersInSession(filters);
            let dateChanged = this.parseDate();
            this.loadProperties();
            this.getPricesText();

            if (dateChanged) {
                this.dispatchDateChangeEvent();
            }
        });

        // enlever ?reset=1 si présent dans l'url sans recharger la page
        if (window.location.search.includes('reset=1')) {
            const newUrl = new URL(window.location);
            newUrl.searchParams.delete('reset');
            window.history.pushState({}, '', newUrl);
        }

    },

    setExistingFilters(userFilters = null) {
        this.originalFilters();

        if ("price_min" in userFilters || "price_max" in userFilters) {
            userFilters.price = {
                min: userFilters.price_min ?? null,
                max: userFilters.price_max ?? null,
            };
            delete userFilters.price_min;
            delete userFilters.price_max;
        }

        let arrayFilters = ['bedrooms', 'collections', 'locations', 'amenities'];

        // Foreach key transform value to array if comma separated
        for (const [key, value] of Object.entries(userFilters)) {
            if (arrayFilters.includes(key)) {
                if (typeof value === 'string')
                    userFilters[key] = value.split(',');
            } else {
                userFilters[key] = [value];
            }
        }

        if ("date_start" in userFilters || "date_end" in userFilters || "date_flexible_in_days" in userFilters) {
            userFilters.date = {};
            userFilters.date.start = userFilters.date_start ?? null;
            userFilters.date.end = userFilters.date_end ?? null;
            userFilters.date.flexibleInDays = userFilters.date_flexible_in_days ?? 0;

            if ("date_start" in userFilters && "date_end" in userFilters) {
                this.inputDate = userFilters.date.start + ' to ' + userFilters.date.end;
            }

            delete userFilters.date_start;
            delete userFilters.date_end;
            delete userFilters.date_flexible_in_days;
        }

        // merge user filters with original filters
        this.filters = Object.assign(this.originalFilters(), userFilters);
    },

    originalFilters() {
        return {
            bedrooms: [],
            collections: [],
            locations: [],
            amenities: [],
            price: {
                min: null,
                max: null,
            },
            date: {
                start: null,
                end: null,
                flexibleInDays: 0,
            },
        }
    },

    countActiveFilters() {
        let count = 0;
        for (const [key, value] of Object.entries(this.filters)) {
            if (key === 'date') {
                continue;
            }
            if (key === 'price') {
                if (value.min || value.max) {
                    count++;
                }
            } else if( !this.excludeFilters.includes(key)) {
                if (value.length > 0) {
                    count++;
                }
            }
        }
        return count;
    },

    isActiveFilterDate() {
        return this.filters.date.start !== null && this.filters.date.end !== null;
    },

    activeFilterDateText() {
        if (!this.isActiveFilterDate()) {
            return '';
        }
        let dateStart = moment(this.filters.date.start);
        let dateEnd = moment(this.filters.date.end);
        return dateStart.format("MMM D, YYYY") + ' → ' + dateEnd.format("MMM D, YYYY");
    },

    loadProperties() {
        let params = this.parseparams();
        axios.get('/villa-rentals?' + params.join('&')).then(response => {
            this.properties_count = response.data.properties.total;
        });
        this.filters.reset = false;
    },

    countProperties() {
        return this.properties_count;
    },

    dispatchClearDateEvent() {
        this.inputDate = null;
        window.dispatchEvent(new CustomEvent('clearDate', {bubbles: true}));
    },

    dispatchDateChangeEvent() {
        this.parseDate();

        window.dispatchEvent(new CustomEvent('dateChange', {
            bubbles: true,
            detail: {
                start: this.filters.date.start,
                end: this.filters.date.end,
                flexibleInDays: this.filters.date.flexibleInDays,
            }
        }),);
    },

    dispatchReloadCalendar() {
        window.dispatchEvent(new CustomEvent('reloadCalendar'));
    },

    storeFiltersInSession(route) {
        sessionStorage.setItem('filters', JSON.stringify(this.filters));
        switch (route) {
            case 'en.location.byslug':
            case 'fr.location.byslug':
            case 'en.collection.byslug':
            case 'fr.collection.byslug':
            case 'en.location.collection':
            case 'fr.location.collection':
                this.submitByReload();
                break;
            default:
                break
        }
    },

    loadFiltersFromSession() {
        let filtersStorage = sessionStorage.getItem('filters');

        if (filtersStorage === null) {
            this.filters = this.originalFilters();
            return;
        }

        let filters = JSON.parse(filtersStorage);

        for (const [key, value] of Object.entries(filters)) {
            if (value === 'null') {
                filters[key] = null;
            }
        }

        if (filters.date.start != null && filters.date.end != null) {
            this.inputDate = filters.date.start + ' to ' + filters.date.end;
        }
        this.filters = filters;
    },

    hasFiltersInUrl() {
        return window.location.search.length > 0;
    },

    loadFiltersFromUrl() {
        let urlParams = new URLSearchParams(window.location.search);
        let filters = this.originalFilters();

        for (const [key, value] of urlParams) {
            if (key === 'price_min' || key === 'price_max') {
                filters.price = {
                    min: urlParams.get('price_min'),
                    max: urlParams.get('price_max'),
                };
            } else if (key === 'date_start' || key === 'date_end' || key === 'date_flexible_in_days') {
                filters.date = {
                    start: urlParams.get('date_start'),
                    end: urlParams.get('date_end'),
                    flexibleInDays: urlParams.get('date_flexible_in_days'),
                };
            } else {
                filters[key] = value.split(',');
            }
        }

        if (filters.date.start != null && filters.date.end != null) {
            this.inputDate = filters.date.start + ' to ' + filters.date.end;
        }

        this.filters = filters;
    },

    getPricesText() {
        if (this.filters.price.min === null && this.filters.price.max === null) {
            return;
        }

        axios.post('api/prices-text', {
            prices: this.filters.price,
            currency: this.currency,
        })
            .then(response => {
                this.priceText = response.data;
            })
    },

    setCurrency(n) {
        this.currency = n;
    },

    clear(field = null) {
        if (field) {
            if (field === 'date') {
                this.dispatchClearDateEvent();
                this.filters.date.flexibleInDays = null;
                this.filters.reset = true;
                sessionStorage.setItem('filters', JSON.stringify(this.filters));
                //this.submitByReload(); // no refresh page + keep modal open
            } else {
                this.filters[field] = this.originalFilters()[field];
            }
        } else {
            this.filters.reset = true;
            let resetFilters = this.originalFilters();
            delete resetFilters.date;
            this.filters =  {...this.filters, ...resetFilters};
            //this.dispatchClearDateEvent();
        }
    },

    parseDate() {
        let beginStart = this.filters.date.start;
        let beginEnd = this.filters.date.end;

        if (this.inputDate !== null) {
            let dates = this.inputDate.split(' to ');
            this.filters.date.start = dates[0];
            this.filters.date.end = dates[1] ?? null;
        } else {
            this.filters.date.start = null;
            this.filters.date.end = null;
        }

        return beginStart !== this.filters.date.start || beginEnd !== this.filters.date.end;
    },

    parseparams() {
        this.parseDate();
        let params = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if (key === 'price') {
                if (value.min) {
                    params.push('price_min=' + value.min);
                }
                if (value.max) {
                    params.push('price_max=' + value.max);
                }
            } else if (key === 'date') {
                if (value.start) {
                    params.push('date_start=' + value.start);
                }
                if (value.end) {
                    params.push('date_end=' + value.end);
                }
                if (value.flexibleInDays) {
                    params.push('date_flexible_in_days=' + value.flexibleInDays);
                }
            }  else if (key === 'reset' && value === true) {
                params.push('reset=1');
            } else if( !this.excludeFilters.includes(key)) {
                    if (value && value.length > 0) {
                        params.push(key + '=' + value.join(','));
                    }
                }
            }

        return params;
    },

    submitByReload() {
        let params = this.parseparams();
        // remove query from current page
        let url = window.location.href.split('?')[0];
        window.location.href = url + (params.length ? '?' + params.join('&') : '' );
    },

    submitSearch() {
        let params = this.parseparams();

        window.location.href = '/villa-rentals' + (params.length ? '?' + params.join('&') : '' ) ;
    },

    shouldDisabledPrice(price, type) {
        if (type === 'min') {
            return price >= this.filters.price.max && this.filters.price.max !== null;
        } else {
            return price <= this.filters.price.min && this.filters.price.min !== null;
        }
    },
})
