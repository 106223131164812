import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
    Alpine.data('searchFast', () => ({
        properties: [],
        filters: {},
        abortController: null,
        searching: false,


        init() {
            this.filters.search = null;
            this.$watch('filters.search', () => {
                this.searchProperties();
            });
        },

        async searchProperties() {

            if (this.filters.search.length < 3) {
                return;
            }

            if(this.searching) {
                this.abortController.abort();
            }

            this.searching = true;
            this.abortController = new AbortController();

            // Todo : unload event JS when new seach (memory leaks)
            // Todo : empty field when mouseEnter

            // Json response (in the future)
            // let responseJson = await axios.post('/villa-rentals/search', {
            //     filters: this.filters,
            // });
            // this.properties = responseJson.data;

            // Html response
            let responseHtml = await axios.get( '/villa-rentals/modals/search', {
                signal: this.abortController.signal,
                responseType: 'text',
                params: { 'search': this.filters.search }
            })
            .then(function (response) {

                // Target
                var modalContent = document.querySelector('#searchFast .modal-results');

                // Create a substitute element
                var contentToLoad = document.createElement('div');
                contentToLoad.classList.add("modal-results");

                // Replace the substitue node with the new content
                contentToLoad.innerHTML = response.data;
                modalContent.parentNode.replaceChild(contentToLoad, modalContent);

                // BS Carousel Lazyload
                contentToLoad.querySelectorAll('img[data-bs-src]').forEach(function (image) {
                    if( image && image.dataset.bsSrc !== undefined ){
                        image.src = image.dataset.bsSrc;
                        image.removeAttribute('bs-src');
                    }
                });
                contentToLoad.querySelectorAll('source[data-bs-srcset]').forEach(function (source) {
                    if( source && source.dataset.bsSrcset !== undefined ){
                        source.srcset = source.dataset.bsSrcset;
                        source.removeAttribute('bs-srcset');
                    }
                });

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then( () => {
                this.searching = false;
            });

        },

    }))
})
