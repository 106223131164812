/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";
import "./slider";
import "./swiper"; // Sliders in Product Page
import "./searchFast";
import "./stickyFilters";
import "./href";
import Alpine from 'alpinejs';
import { scrollDirection } from './scrollDirection';
import intersect from '@alpinejs/intersect'
import favorites from "./favorites";

import "./simpleForm";
import propertyBook from "./propertyBook";
import "./populateForms";

import rateDisplay from "./rateDisplay";
import flatpickr from "flatpickr";
//import AOS from "aos"; // no trigger correctly with large section

import "./store/filters.store";
import "./store/property.store";

// Alpine.data('searchFilters', searchFilters);
Alpine.data('favorites', favorites);
Alpine.data('propertyBook', propertyBook);
Alpine.data('rateDisplay', rateDisplay);
Alpine.data('scrollDirection', scrollDirection);
Alpine.plugin(intersect);

window.Alpine = Alpine;
window.intersect = intersect;


Alpine.start();


import lazysizes from 'lazysizes';

import "./framework-bootstrap"; // Modal, Carousel, Placeholder
