import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'

document.addEventListener('alpine:init', () => {
    Alpine.data('navFilters', () => ({
        hasLoaded: false,
        filtersHero: document.getElementById('filtersHero'),
        filtersHeader: document.getElementById('filtersHeader'),
        init() {

        },
        async toogleStickyFilters(displayFilters) {

            const scrollToTop = window.pageYOffset || document.documentElement.scrollTop;

            // loading | interactive | complete
            if (document.readyState === "loading") {
                // console.log('readyState = "loading"');
                return;
            }

            if (displayFilters) {

                // console.log('displayFilters = true');
                if(document.querySelector('.header .filters') == null ){
                    // console.log('Filters => Move to header');
                    moveNode(this.filtersHeader, this.filtersHero);
                }

            } else {

                // console.log('displayFilters = false');
                if(document.querySelector('.section-filters .filters') == null ){
                    // console.log('Filters => Move to content page');
                    moveNode(this.filtersHero, this.filtersHeader);
                }

            }

        },

    }))
})
